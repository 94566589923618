import {graphql} from 'gatsby'
import Img from 'gatsby-image'
import Link from 'gatsby-link'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'

import ClearSpacer from './../components/ClearSpacer'
import BookAttachedContent from './../components/book-entry/BookAttachedContent'

export const BookEntryTemplate = ({ descHtml, frontmatter, helmet }) => {
  const {
    title,
    author,
    isbn,
    datePublished,
    copyrightYear,
    format,
    pages,
    imprint,
    price,
    cover,
    attachedContent
  } = frontmatter

  const datePublishedFormatted =
      new Date(datePublished).toLocaleDateString('en-GB')

  const imgShadow = { boxShadow: '3px 2px 8px rgba(55,55,55,0.3)' }


  return (
    <div className='container-main'>
      {helmet || ''}
      <div className='bookentry'>
        <div className='bookentry__coverimg'>
          <Img style={imgShadow} fluid={
    cover.childImageSharp.fluid} />
        </div>
        <div className='bookentry__titleauthordesc'>
          <h3 className='bookentry__title'>{title}</h3>
          <h4 className='bookentry__author'>{author}</h4>
          <ClearSpacer gap='1' top bottom />
          <div className='bookentry__description' dangerouslySetInnerHTML={{
    __html: descHtml}}></div>
          <BookAttachedContent attachedContent={attachedContent} />
          <ClearSpacer gap='1' top bottom />
          <div className='bookentry__purchasing'>
            <p className='bookentry__price'>Price: £{price}</p>
            <p className='bookentry__postage'>(Exclusive of postage)</p>
            <Link to='/contact/' state={{
    origin: 'book', title: title }}>
              <p className='bookentry__purchase'>Contact us about purchasing</p>
            </Link>
          </div>
        </div>
        <div className='bookentry__meta'>
          <p>
            <span className='bookentry__meta--field'>Imprint</span>: {imprint}
          </p>
          <p>
            <span className='bookentry__meta--field'>ISBN</span>: {isbn}
          </p>
          <p>
            <span className='bookentry__meta--field'>Pages</span>: {pages}
          </p>
          <p>
            <span className='bookentry__meta--field'>Format</span>: {format}
          </p>
          <p>
            <span className='bookentry__meta--field'>Published</span>: {datePublishedFormatted}
          </p>
          <p>
            <span className='bookentry__meta--field'>Copyright</span>: {copyrightYear}
          </p>
        </div>
      </div>
    </div>
  )
}

BookEntryTemplate.propTypes = {
  frontmatter: PropTypes.object.isRequired,
  helmet: PropTypes.object,
}

const BookEntry = ({ data }) => {
  const { markdownRemark: book } = data

  return (
    <Layout>
      <BookEntryTemplate
        descHtml={book.html}
        frontmatter={book.frontmatter}
        helmet={
          <Helmet titleTemplate={`Books | %s`}>
            <title>{`${book.frontmatter.title}`}</title>
            <meta name='description' content={
    `${book.frontmatter.description}`} />
          </Helmet>
        }
      />
    </Layout>
  )
}

BookEntry.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BookEntry

export const pageQuery = graphql`
  query BookByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        author
        isbn
        datePublished
        copyrightYear
        format
        pages
        imprint
        price
        attachedContent {
          label
          url
        }
        cover {
          childImageSharp {
            fluid(maxWidth: 350, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
