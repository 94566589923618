import React from 'react'
import Layout from '../components/Layout'
import ClearSpacer from './../components/ClearSpacer'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet';

export default function About({ data }) {
  const { heading, aboutText } = data.aboutPage.frontmatter
  return (
    <Layout activePage='about'>
      <Helmet titleTemplate={`Abercromby Press | %s`}>
        <title>About Us</title>
        <meta name='description' content={`About us`} />
      </Helmet>
      <div className='container-main'>
        <h3 className='about__heading'>{heading}</h3>
        <ClearSpacer gap='1' top bottom />
        <p className='about__text'>{aboutText}</p>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutPage {
    aboutPage: markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        heading
        aboutText
      }
    }
  }
`
