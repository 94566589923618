import React from 'react'

export default function BotField({handleChange}) {
    return (
        <div hidden>
        <label>
          Don’t fill this out: <input name='bot-field' onChange={handleChange} />
        </label>
      </div>
    )
}
