import React from 'react'

export default function InlineField({ fieldName, label, placeholder, handleChange, value }) {
  return (
    <div className='contact__field'>
      <label htmlFor={fieldName} className='contact__field--label'>
        {label}
      </label>
      <input
        className={`contact__field--input ${fieldName}`}
        type={fieldName === 'email' ? 'email' : 'text'}
        name={fieldName}
        onChange={handleChange}
        id={fieldName}
        required={true}
        placeholder={placeholder}
        value={value}
      />
    </div>
  )
}
