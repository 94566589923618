import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div className='container-main notfound'>
      <h3>Not Found</h3>
      <p>The page you have tried to view cannot be found</p>
    </div>
  </Layout>
)

export default NotFoundPage
