import React, { useState } from 'react'
import { navigate } from 'gatsby'
import InlineField from './InlineField'
import MessageField from './MessageField'
import SendButton from './SendButton'
import BotField from './BotField'
import PrivacyCheckbox from './PrivacyCheckbox'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function ContactForm({ state }) {
  let defaultTitle = ''
  let defaultMessage = ''
  if (state && state.origin) {
    if (state.origin === 'book') {
      defaultTitle = 'Book order inquiry'
    } else if (state.origin === 'submission') {
      defaultTitle = 'Submission inquiry'
    }
  }

  // TODO: prefill message title from book/submissions page

  const defaultFormState = {
    name: '',
    email: '',
    subject: defaultTitle,
    privacy: false,
    message: defaultMessage,
  }

  const [formState, setFormState] = useState({ ...defaultFormState })


  function handleChange(e) {
    if (e.target.type === 'checkbox') {
      setFormState({
        ...formState,
        [e.target.name]: e.target.checked,
      })
    } else {
      setFormState({
        ...formState,
        [e.target.name]: e.target.value,
      })
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formState,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  return (
    <form
      className='contact__form'
      name='contact'
      method='post'
      action='/contact/thanks/'
      data-netlify='true'
      data-netlify-honeypot='bot-field'
      onSubmit={handleSubmit}>
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type='hidden' name='form-name' value='contact' />
      <BotField handleChange={handleChange} />
      <InlineField fieldName='name' label='Name' type='text' placeholder='John Doe' handleChange={handleChange} />
      <InlineField
        fieldName='email'
        label='E-mail'
        type='email'
        placeholder='e.g. johndoe@email.com'
        value={formState.email}
        handleChange={handleChange}
      />
      <InlineField
        fieldName='subject'
        label='Subject'
        type='text'
        placeholder='Subject'
        value={formState.subject}
        handleChange={handleChange}
      />
      <MessageField handleChange={handleChange} value={formState.message} />
      <PrivacyCheckbox fieldName='privacy' handleChange={handleChange} />
      <SendButton enabled={formState.privacy} />
    </form>
  )
}
