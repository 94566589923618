import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import logo from '../img/logogrey.svg'

function Navbar({ activePage }) {
  // Class to add and remove from navbar
  const menuOpenClass = 'open'
  const buttonActiveClass = 'close'

  const [menuOpen, setMenuOpen] = useState(false)
  const [menuClass, setMenuClass] = useState('')
  const [buttonClass, setButtonClass] = useState('')

  function toggleMenu() {
    setMenuOpen(!menuOpen)
  }

  useEffect(() => {
    function updateClasses() {
      if (menuOpen === true) {
        setMenuClass(menuOpenClass)
        setButtonClass(buttonActiveClass)
      } else {
        setMenuClass('')
        setButtonClass('')
      }
    }

    updateClasses()
  }, [menuOpen])

  return (
    <nav className='nav' role='navigation' aria-label='main-navigation'>
      <div className='nav__container'>
        <div className='nav__brand'>
          <Link to='/'>
            <img src={logo} alt='' />
            <span>Abercromby Press</span>
          </Link>
          {/* Hamburger menu */}
          <div className={`menu-btn ${buttonClass}`} onClick={toggleMenu}>
            <div className='btn-line'></div>
            <div className='btn-line'></div>
            <div className='btn-line'></div>
          </div>
        </div>
        <ul className={`nav__menu ${menuClass}`}>
          <Link to='/books'>
            <li className={`nav__item ${menuClass} ${activePage === 'books' ? 'active' : ''}`}>Books</li>
          </Link>
          <Link to='/submissions'>
            <li className={`nav__item ${menuClass} ${activePage === 'submissions' ? 'active' : ''}`}>Submissions</li>
          </Link>
          <Link to='/about'>
            <li className={`nav__item ${menuClass} ${activePage === 'about' ? 'active' : ''}`}>About</li>
          </Link>
          <Link to='/contact'>
            <li className={`nav__item ${menuClass} ${activePage === 'contact' ? 'active' : ''}`}>Contact</li>
          </Link>
        </ul>
      </div>
    </nav>
  )
}

export default Navbar
