import React from 'react'

export default function ClearSpacer({gap, top, bottom}) {
  return (
    <>
    {top && <div className="clear" style={{paddingTop: gap + 'rem'}}></div>}
    <hr className="border"/>
    {bottom && <div className="clear" style={{paddingTop: gap + 'rem'}}></div>}
    </>
  )
}
