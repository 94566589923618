import React from 'react'

export default function MessageField({ handleChange, value }) {
  return (
    <div className='contact__field'>
      <label className='contact__field--label' htmlFor={'message'}>
        Message
      </label>
      <textarea
        className='contact__textbox'
        name={'message'}
        onChange={handleChange}
        id={'message'}
        required={true}
        value={value}
      />
    </div>
  )
}
