import React from 'react'
import Layout from '../../components/Layout'
import ClearSpacer from '../../components/ClearSpacer'

export default function ContactThanks() {
  return (
    <Layout activePage='contact'>
      <div className='container-main'>
        <div className='thanks'>
          <h2 className='thanks__title'>Your message has been received</h2>
          <ClearSpacer gap='1' top bottom />
          <p className='thanks__text'>
            We will reply as soon as possible (typically within 2 working days) via your provided e-mail.
          </p>
          <p className='thanks__link'>
            <a href='/'>Return to homepage</a>
          </p>
        </div>
      </div>
    </Layout>
  )
}
