import React from 'react'
import Img from 'gatsby-image'
import Link from 'gatsby-link'

export default function AllBooks({ books }) {
  return (
    <ul className={`allbooks__list`}>
      {books.map(book => (
        <li key={book.id} className={`allbooks__tile`}>
          <Link to={book.fields.slug}>
            <Img fluid={book.frontmatter.cover.childImageSharp.fluid} />
            <div className='allbooks__tiledetails'>
              <p className='title'>{book.frontmatter.title}</p>
              <p className='author'>{book.frontmatter.author}</p>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  )
}
