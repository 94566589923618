import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import ClearSpacer from './../components/ClearSpacer'
import ContactForm from './../components/contact/form/ContactForm'
import { Helmet } from 'react-helmet';

const ContactPage = ({ data, location }) => {
  const contactText = data.contactPage.frontmatter.contactText
  return (
    <Layout activePage='contact'>
      <Helmet titleTemplate={`Abercromby Press | %s`}>
        <title>Contact Us</title>
        <meta name='description' content={`Contact us`} />
      </Helmet>
      <div className='container-main'>
        <div className='contact'>
          <h2 className='contact__title'>Contact Us</h2>
          <ClearSpacer gap='1' top bottom />
          <p className='contact__description'>{contactText}</p>
          <ContactForm state={location.state} />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContactPage {
    contactPage: markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        contactText
      }
    }
  }
`

export default ContactPage
