import React from 'react'
import ClearSpacer from '../ClearSpacer'

export default function BookAttachedContent({ attachedContent }) {
    if (attachedContent == null) {
        return React.Fragment
    } else
  return <>
    <ClearSpacer gap='1' top bottom />
    <div className="bookentry__attachedcontent">
      <h5>Available Content:</h5>
      <ul>
          {attachedContent.map(ac => 
              <li><a href={ac.url} target="_blank">{ac.label}</a></li>
          )}
      </ul>
    </div>

  </>
  
}
