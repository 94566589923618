import React from 'react'

export default function PrivacyCheckbox({ fieldName, handleChange }) {
  return (
    <div className='contact__privacy'>
      <input
        className={`contact__privacy--input ${fieldName}`}
        type={'checkbox'}
        name={fieldName}
        onChange={handleChange}
        id={fieldName}
        required={true}
      />
      <label htmlFor={fieldName} className='contact__privacy--label'>
        By checking this box, you are agreeing to the terms set out in our <a href='/privacy'>privacy policy</a>, and
        give consent to be contacted by us via e-mail.
      </label>
    </div>
  )
}
