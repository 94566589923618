import React from 'react'
import ClearSpacer from '../ClearSpacer'

export default function ForthcomingBooks({ books, heading, children }) {
  return (
    <section className='forthcoming-publications'>
      <h3 className=''>{heading}</h3>
      <ClearSpacer gap='1' top bottom />
      <ul className={`index-forthcoming-container`}>
        {books.map(book => (
          <li key={book.id} className='index-forthcoming-item'>
            <p className='index-forthcoming-item__title'>{book.frontmatter.title} <span className='index-forthcoming-item__title--year'>({book.frontmatter.year})</span></p>
            <p className='index-forthcoming-item__author'>{book.frontmatter.author}</p>
            <div className='index-forthcoming-item__description'dangerouslySetInnerHTML={{
    __html: book.html}}>{book.frontmatter.description}</div>
          </li>
        ))}
      </ul>
      {children}
    </section>
  )
}
