import React from 'react'
import Img from 'gatsby-image'
import ClearSpacer from '../ClearSpacer'
import Link from 'gatsby-link'

export default function LatestPublications({ books, heading, children }) {
  const bookCount = books.length
  return (
    <section className='latest-publications'>
      <h3 className=''>{heading}</h3>
      <ClearSpacer gap='1' top bottom />
      <ul className={`index-publication-container count-${bookCount}`}>
        {books.map(book => (
          <li key={book.id} className={`latest-publication-tile count-${bookCount}`}>
            <Link to={book.fields.slug}>
              <Img fluid={book.frontmatter.cover.childImageSharp.fluid} />
              <div className='book-details'>
                <p className='title'>{book.frontmatter.title}</p>
                <p className='author'>{book.frontmatter.author}</p>
              </div>
            </Link>
          </li>
        ))}
      </ul>
      {children}
    </section>
  )
}
