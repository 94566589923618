import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'

import Layout from '../components/Layout'
import Banner from './../components/index/Banner'
import IndexPublicationDisplay from '../components/index/IndexPublicationDisplay'
import ForthcomingBooks from '../components/index/ForthcomingBooks'

export const IndexPageTemplate = ({ image, bannerTitle, bannerSubtitle, latestBooks, upcomingBooks }) => (
  <div>
    <Banner image={image} bannerTitle={bannerTitle} bannerSubtitle={bannerSubtitle} />
    <div className='container-main'>
      <IndexPublicationDisplay books={latestBooks} heading='Our Latest Books'>
        <div className='btn-view-more'>
          <Link to='/books/'>
            <p>View all books</p>
          </Link>
        </div>
      </IndexPublicationDisplay>
      <ForthcomingBooks books={upcomingBooks} heading='Forthcoming Books' />
    </div>
  </div>
)

IndexPageTemplate.propTypes = {}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.page
  const latestBooks = data.latestBooks.nodes
  const upcomingBooks = data.upcomingBooks.nodes

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        heading={frontmatter.heading}
        bannerTitle={frontmatter.bannerTitle}
        bannerSubtitle={frontmatter.bannerSubtitle}
        introductionText={frontmatter.introductionText}
        latestBooks={latestBooks}
        upcomingBooks={upcomingBooks}
      />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    page: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        bannerTitle
        bannerSubtitle
        introductionText
        heading
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    latestBooks: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "book-entry" } } }
      limit: 4
      sort: { order: DESC, fields: frontmatter___datePublished }
    ) {
      nodes {
        id
        frontmatter {
          title
          author
          cover {
            childImageSharp {
              fluid(maxWidth: 276, maxHeight: 400, quality: 90, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
    upcomingBooks: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "book-upcoming" } } }) {
      nodes {
        id
        html
        frontmatter {
          title
          author
          year
        }
      }
    }
  }
`
