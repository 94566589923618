import React from 'react'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className='footer'>
        <p className='footer__content copyright'>© Abercromby Press {String(new Date().getFullYear())}</p>
        <p className='footer__content privacy'>
          <a href='/privacy'>Privacy policy</a>
        </p>
      </footer>
    )
  }
}

export default Footer
