import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import AllBooks from '../../components/books/AllBooks'
import ClearSpacer from './../../components/ClearSpacer'
import { Helmet } from 'react-helmet'

export const BooksPageTemplate = ({ books }) => (
  <div className='container-main'>
    <h3 className='allbooks__heading'>Our Collection</h3>
    <ClearSpacer gap='1' top bottom />
    <AllBooks books={books} />
  </div>
)

const BooksPage = ({ data }) => {
  const books = data.books
  return (
    <Layout activePage='books'>
      <Helmet titleTemplate={`Abercromby Press | %s`}>
        <title>Books</title>
        <meta name='description' content={`Our books`} />
      </Helmet>
      <BooksPageTemplate books={books.nodes} />
    </Layout>
  )
}

export default BooksPage

export const pageQuery = graphql`
  query BooksPage {
    books: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "book-entry" } } }
      sort: { order: DESC, fields: frontmatter___datePublished }
    ) {
      nodes {
        id
        frontmatter {
          title
          author
          cover {
            childImageSharp {
              fluid(maxWidth: 276, maxHeight: 400, quality: 75, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
`
