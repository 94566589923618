import React from 'react'
import Layout from '../components/Layout'
import ClearSpacer from './../components/ClearSpacer'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet';

export const SubmissionsPageTemplate = () => <div></div>

const SubmissionsPage = ({ data }) => {
  const submissionsText = data.submissionsPage.frontmatter.submissionsText
  return (
    <Layout activePage='submissions'>
      <Helmet titleTemplate={`Abercromby Press | %s`}>
        <title>Submissions</title>
        <meta name='description' content={`Submissions`} />
      </Helmet>
      <div className='container-main'>
        <h2 className='submissions__title'>Submissions</h2>
        <ClearSpacer top bottom gap='1' />
        <div className='submissions__container'>
          <p className='submissions__maintext'>{submissionsText}</p>
          <Link to='/contact/' state={{ origin: 'submission' }}>
            <p className='submissions__button'>Contact us about submissions</p>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query SubmissionsPage {
    submissionsPage: markdownRemark(frontmatter: { templateKey: { eq: "submissions-page" } }) {
      frontmatter {
        submissionsText
      }
    }
  }
`

export default SubmissionsPage
