import React from 'react'

export default function Banner({ image, bannerTitle, bannerSubtitle }) {
  return (
    <div
      className='banner full-width-image'
      style={{
        backgroundImage: `url(${!!image.childImageSharp ? image.childImageSharp.fluid.src : image})`,
      }}>
      <div className='banner__gradient'></div>
      <div className='title-container'>
        <h3 className='banner__title'>{bannerTitle}</h3>
        <h4 className='banner__subtitle'>{bannerSubtitle}</h4>
        <a href='/books'>
          <button className='banner__button'>
            View our Catalogue of Books
          </button>
        </a>
      </div>
    </div>
  )
}
